<template>
  <v-form v-model="valid">
    <v-container>
      <h5>{{ $t('lang.register_cage') }}</h5>
      <v-row>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="org"
            :rules="norwegianNumberValidation"
            :label="$t('lang.org')"
            required
            @input="$emit('save-handler', org, rec)"
          ></v-text-field>
        </v-col> 

        <v-col cols="12" md="5">
          <v-text-field
            v-model="rec"
            :rules="norwegianNumberValidation"
            :label="$t('lang.rec')"
            required
            @input="$emit('save-handler', org, rec)"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn
            class="mx-2"
            dark
            color="indigo"
            @click="registerCageLog"
            v-if="!btnDisabled"
          >
            {{ $t('lang.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: 'RegisterCageForm',
  data() {
    return {
      valid: false,
      org: null,
      rec: null,
      numberRules: [v => !!v || 'Required field'],
      norwegianNumberValidation: [
                                    val => (val) ? /^[0-9,]+$/.test(val) || 'Not Valid Number' : true,
                                    val => (val) ? !isNaN(this.replaceCommaWithDot(val)) || 'Not Valid Number' : true
                                ], 
    }
  },
  computed: {
    btnDisabled() {
      return this.org !== null && this.rec !== null ? false : true
    }
  },
  methods: {
    registerCageLog() {
      this.$emit('submitted', {
        org: this.replaceCommaWithDot(this.org),
        rec: this.replaceCommaWithDot(this.rec)
      })
    },
  }
}
</script>
