<template>
  <v-content class="grey lighten-3">
    <v-container>
      <v-row>
        <v-col
          ><strong>{{ $t('lang.average_fill') }}</strong>: </strong>
          {{ replaceDotWithCommaOneDigit(averageFill) }}
        </v-col>
        <!-- <v-col><strong>Numnber of stops: </strong> {{ numberodStops }} </v-col> -->
      </v-row>
      <v-row>
        <v-col><strong>{{ $t('lang.total_org') }}: </strong> {{ replaceDotWithCommaOneDigit(totalOrg) }} </v-col>
        <v-col><strong>{{ $t('lang.total_rec') }}: </strong> {{ replaceDotWithCommaOneDigit(totalRec) }} </v-col>
        <!-- <v-col></v-col> -->
      </v-row>
    </v-container>
  </v-content>
</template>
<script>
export default {
  name: 'RouteSummary',
  props: {
    totalKm: {
      type: [String, Number]
    },
    averageFill: {
      type: [String, Number]
    },
    numberodStops: {
      type: [String, Number]
    },
    totalOrg: {
      type: [String, Number]
    },
    totalRec: {
      type: [String, Number]
    }
  },
  methods: {
    formateDecimal(value) {
      if (value) {
        return parseFloat(value).toFixed(1)
      }
      return 0
    }
  }
}
</script>
