<template>
  <v-container>
    <!-- <v-row>
      <v-col md="5">ORG</v-col>
      <v-col md="5">REC</v-col>
      <v-col md="2"></v-col>
    </v-row> -->
    <v-row
      v-for="log in cageLogs"
      :key="log.id"
      style="border-bottom: solid 1px #CFD8DC"
    >
      <v-col md="1"># {{ log.id }} </v-col>
      <v-col md="5">{{ replaceDotWithCommaOneDigit(log.org) }}</v-col>
      <v-col md="5">{{ replaceDotWithCommaOneDigit(log.rec) }}</v-col>
      <v-col md="1">
        <v-btn
          class="mx-2"
          fab
          dark
          color="indigo"
          x-small
          @click="deleteLog(log)"
        >
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CageLogList',
  props: {
    cageLogs: {
      type: Array
    }
  },
  methods: {
    deleteLog(log) {
      this.$emit('onDeleteLog', log)
    },
  }
}
</script>

<style></style>
