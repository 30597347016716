import request from '@/utils/request';

const BASE_URL = process.env.VUE_APP_BASE_URL;

/**
 * 
 * Get Deviation Data
 */
const getDeviationData = () => {
    return request({
        url: `${BASE_URL}/deviationCategories`,
        method: 'GET'
    });
}

export {
    getDeviationData
}