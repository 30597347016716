<template>
  <v-card class="mx-auto">
    <v-card-text>
      <p class="display-1 text--primary">
        {{ $t('lang.route_summary') }}
      </p>
    </v-card-text>
    <route-summary
      :total-km="routeLog.total_km"
      :average-fill="averageFill"
      number-if-stops="n/a"
      :total-rec="recTotal"
      :total-org="net"
    ></route-summary>
    <cage-form
      @submitted="cageFormSubmitted"
      @save-handler="showAndHideSaveButton"
      v-if="loadCageLogForm"
    ></cage-form>
    <cage-log-list
      v-if="routeLog.cage_logs !== null"
      :cage-logs="routeLog.cage_logs"
      @onDeleteLog="deleteLog"
    ></cage-log-list>

    <v-card-actions>
      <v-btn @click="saveAndClose" class="ma-2" color="success"
        >{{ $t('lang.saveAndClose') }}
        <v-icon right>mdi-checkbox-marked-circle</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { saveCageLog, deleteLog } from '@/api/cageLog'
import { saveSummary, getAverageFill, checkCloseRoute} from '@/api/routelogs'
import RouteSummary from './components/RouteSummary'
import RegisterCageForm from './components/RegisterCageForm'
import CageLogList from './components/CageLogList'

@Component({
  props: {
    routeLog: {
      type: [Object, null],
      required: true
    },
  },
  components: {
    'route-summary': RouteSummary,
    'cage-form': RegisterCageForm,
    'cage-log-list': CageLogList
  },
  computed: {

    getRouteLogCagelogs() {
      return this.routeLog.cage_log !== null ? this.routeLog.cage_log : []
    }
  }
})
class EmptyingTruck extends Vue {
  data() {
    return {
      loadCageLogForm: true,
      averageFill: null,
      recTotal: 0,
      net: 0,
      isSaveDisabled: false
    }
  }

  mounted() {
    for (let i = this.routeLog.cage_logs.length - 1; i >= 0; i--) {
      this.recTotal = parseFloat(this.routeLog.cage_logs[i].rec) + parseFloat(this.recTotal)
      this.net = parseFloat(this.routeLog.cage_logs[i].org) + parseFloat(this.net)
    }
    this.net = parseFloat(this.net).toFixed(1)
    this.recTotal = parseFloat(this.recTotal).toFixed(1)
    this.getAverageFill()
  }

  cageFormSubmitted(payload) {
    saveCageLog({
      ...payload,
      route_log_id: this.routeLog.id
    })
      .then(response => {
        this.reloadCageLogForm()
        this.$emit('cageLogsChanged')
        this.getAverageFill()
      })
      .catch(err => {
        console.log(err)
      })
  }

  deleteLog(payload) {
    deleteLog(payload.id).then(response => {
      this.$emit('cageLogsChanged')
    })
  }

  reloadCageLogForm() {
    this.loadCageLogForm = false
    this.$nextTick(() => {
      this.loadCageLogForm = true
    })
  }

  saveAndClose() {
    if (!this.isSaveDisabled) {
    checkCloseRoute(this.routeLog.id)
      .then(response => {
        if (response.canClose === 1) {
          saveSummary({ id: this.routeLog.id }).then(data => {
            this.handleNotificationSuccessOrFainMsg(this.$t("lang.routeClosedSuccessfully"), 'success')
            this.$router.push('/')
          })
        } else {
          this.handleNotificationSuccessOrFainMsg(this.$t("lang.completeAllRoutes"), 'error')
        }
      })
    } else {
      this.handleNotificationSuccessOrFainMsg(this.$t("lang.save_cage_value"), 'error')
    }
  }

  getAverageFill() {
    const params = {
      id: this.routeLog.id,
      noOfRoutes: this.routeLog.noOfRoutes
    }
    getAverageFill(params).then(response => {
      this.averageFill = response
    })
  }

  showAndHideSaveButton (org, rec) {
    this.isSaveDisabled = false
    if (org || rec) {
      this.isSaveDisabled = true
    }
  }
}

export default EmptyingTruck
</script>

<style></style>
