<template>
  <v-card flat>
    <v-row class="mainRow">
      <v-col cols="12" xs="12" sm="4" md="4" lg="3" class="pa-0" id="listDiv">
        <v-list class="overflow-y-auto v-navigation-drawer" :style="ListStyles">
          <v-list-item-group
            v-model="selected"
            active-class="deep-orange--text"
          >
            <template v-for="(item, index) in items">
              <v-list-item :key="index">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-content
                    @click="onClickOfChildRoute(index, item.lat, item.lon)"
                  >
                    <v-list-item-title
                      v-if="
                        item.deployment_relation != null &&
                        item.deployment_relation.vendor_relation != null
                      "
                      class="title ml-3"
                      v-text="
                        item.deployment_relation.vendor_relation.company_name
                      "
                    />
                    <v-list-item-subtitle
                      v-if="item.deployment_relation"
                      class="subtitle-2 ml-3"
                      v-text="item.deployment_relation.address"
                    />
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
            <v-list-item>
              <v-list-item-content
                @click="emptyingTruckAction"
                v-if="routeLog !== null"
              >
                <v-list-item-title class="title ml-3">
                  {{ $t('lang.emptyTruck') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col :style="mapDivStyle" cols="12" xs="12" sm="8" md="8" lg="9" class="mainDiv" ref="mainDiv" >
        <v-row class="row customDiv" id="customDivId">
          <template v-if="deploymenetDetails">
            <v-col xs="12" ref="detailsDiv"  v-resize="mapCustomStyleForMap">
              <v-container grid-list-md>
                <v-layout row wrap id="deploymenetDetailsDiv">
                  <v-flex xs12 sm9>
                    <strong>{{ staticData.place ? staticData.place : '' }} {{ staticData.address ? ',' : '' }}  {{ staticData.address ? staticData.address : '' }}</strong><span class="ml-3">{{ staticData.container ? staticData.container : '0' }} {{ $t('lang.containers') }}</span>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-btn outlined  class="ma-2 float-sm-right" small color="secondary" @click="imageDialog = true">
                      <v-icon dark>mdi-file-image</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-text-field
                      :label="$t('lang.fillContainer')"
                      clearble
                      v-model="formData.container"
                      type="number"
                      @keyup.native="validateNumber"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-autocomplete
                      v-model="formData.deviation_id"
                      :items="deviationList"
                      :label="$t('lang.deviation')"
                      item-text="name"
                      item-value="id"
                      clearable
                    />
                  </v-flex>
                   <v-flex xs12 sm5>
                    <v-textarea
                      v-model="formData.deviation_comment"
                      :label="$t('lang.comments')"
                      rows="1"
                      clearable
                      auto-grow
                    />
                  </v-flex>
                  <v-flex xs12 sm2 v-if="!deploymenetDisabled">
                    <v-btn @click="saveRoutes" :loading="btnload" small color="primary float-sm-right">{{ $t('lang.save') }}</v-btn>
                  </v-flex>
                  <v-flex xs12 sm2 v-else>
                    <v-btn @click="saveRoutes" :loading="btnload" small color="primary float-sm-right">{{ $t('lang.update') }}</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-col>
          </template>
          <template v-else>
            <v-col xs="12" ref="detailsDiv" v-resize="mapCustomStyleForMap">
              <v-layout wrap>
                <v-flex xs12>
                  <b>{{ routeData ? routeData.name : '' }}</b>
                  <span class="ml-3">{{ routeData ? routeData.start_point_relation.name : '' }} , {{ routeData ? routeData.end_point_relation.name : '' }}</span>
                </v-flex>
              </v-layout>
            </v-col>
          </template>
        </v-row>        
        <div class="searchBox" :style="descriptionHeight">
          <div class="selectedRoute" v-if="directions.length != 0" v-for="(direction, index) in directions" :key="index" v-show="show">
            <v-row dense>
              <v-col v-for="(direction, idx) in directions" :key="idx" cols="6">
                <v-card class="pa-2" v-if="selectedRouteIdx !== null" width="350" :color="selectedRouteIdx === idx ? 'deep-orange' : '#858585'" @click="handleSelectedRoute(idx)">
                  <h5> {{ direction.routeName }} </h5>
                  <h5> {{ formatLength(direction.summary.totalLength) }} {{ '/' }} {{ formatTimeFromMintes(direction.summary.totalDriveTime) }} </h5>
                </v-card>
              </v-col>

               <v-col class="ml-1" cols="5">
                <v-card class="pa-2" max-width="250" color="deep-purple white--text">
                  <h5>{{ $t('lang.drivingTime') }} {{  timeByRoute }}</h5>
                </v-card>
              </v-col>
            </v-row>
            <div
              class="selectedRouteDisplay"
              v-if="selectedRouteIdx >= 0 && direction != null"
            >
              <v-card max-width="360" class="displayRouteDetails mt-1">
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs4>
                      <span>
                        <img src="@/assets/routes/car-icon.png" width="20px" />
                      </span>
                      <span style="font-size: 11px !important">{{ `${directions[selectedRouteIdx].TotalTollSmall}kr` }}</span>
                    </v-flex>
                    
                      <v-flex xs4>
                        <span><img src="@/assets/routes/Truck-icon.png" width="20px" /></span>
                        <span style="font-size: 11px !important">{{ `${directions[selectedRouteIdx].TotalTollLarge}kr` }}</span>
                      </v-flex>
                        
                      <v-flex xs12 v-if="directions[selectedRouteIdx].summary && directions[selectedRouteIdx].summary.statistics && directions[selectedRouteIdx].summary.statistics.DistanceInZeroEmissionZone > 0">
                        <span style="font-size: 11px !important" class="nullutslippszone">
                          Lengde i nullutslippsone: 
                          {{ formatLength(directions[selectedRouteIdx].summary.statistics.DistanceInZeroEmissionZone) }}
                        </span>
                      </v-flex>

                      <div v-if="directions[selectedRouteIdx].TotalTollSmall < directions[selectedRouteIdx].TotalTollSmallWithoutDiscount">
                        Bomstasjonskostnad uten timesregel: 
                        <v-flex xs12 class="bomstasjonnorebate"> {{directions[selectedRouteIdx].TotalTollSmallWithoutDiscount}}
                          <span><img src="@/assets/routes/car-icon.png" width="20px" /></span>
                          <span style="font-size: 11px !important">{{ `${directions[selectedRouteIdx].TotalTollSmallWithoutDiscount}kr` }}</span>
                        </v-flex>
                        
                        <v-flex xs12>
                          <span><img src="@/assets/routes/Truck-icon.png" width="20px" /></span>
                          <span style="font-size: 11px !important">{{ `${directions[selectedRouteIdx].TotalTollLargeWithoutDiscount}kr` }}</span>
                        </v-flex>
                      </div>

                      <v-flex xs12 v-if="selectedRouteIdx >= 0 && directions != null" v-for="(feature, i) in directions[selectedRouteIdx].features" :key="i">
                        <span :class="'signpost ' +'signpost-'+ feature.roadCat" style="font-size: 9px !important" v-if="feature.roadCat">
                          {{`${feature.roadCat} ${feature.roadNumber}`}}
                        </span>
                        <span style="font-size: 9px !important"> {{ feature.attributes.text }} </span> 
                        <span style="font-size: 9px !important" v-if="feature">
                          og kjør <b>{{ formatLength((feature.attributes).length*1000) }}</b>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </div>
            </div>
          </div>
        <div :style="Styles" id="mapid" ref="mapContainer"></div>
      </v-col>
      <v-col v-if="emptyingTruck" cols="12" xs="12" sm="8" md="8" lg="9" id="emptyingTruckDiv">
        <emptying-truck
          :key="emptyingTruckKey"
          :route-log="routeLog"
          @cageLogsChanged="onCageLogsChange"
        ></emptying-truck>
      </v-col>
    </v-row>
    <v-dialog
      v-model="imageDialog"
      max-width="400"
    >
    <v-card>
      <v-card-title>
        {{ staticData.companyName }}
      </v-card-title>
      <v-divider class="mb-3"></v-divider>
      <v-card-text>
          <input
            type="file"
            id="deployment_image"
            name="deployment_image"
            accept="image/*"
            style="display: none"
            @change="getListOfFileNames"
            ref="attachments"
          >
          <v-img
            style="cursor: pointer"
            @click='pickFiles'
            :src="(deploymentImage) ? deploymentImage : ''"
          >
          </v-img>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          class="mt-1"
          color="primary"
          @click="updateDeploymentImage"
          :disabled="updateImage"
        >
          {{ imageAvaliable === 1 ? $t('lang.edit') : $t('lang.updateImage') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mt-1"
          color="error"
          @click="imageDialog = false"
        >
          {{  $t('lang.close') }}
        </v-btn>
     </v-card-actions>
    </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import * as L from "leaflet"
import 'leaflet-easybutton'
import "proj4leaflet"
import Component from 'vue-class-component'
import { mapMutations, mapGetters } from 'vuex'
import { getAllRoutesList, saveRoutesListItems } from '@/api/home'
import { getRouteRouteLog } from '@/api/routelogs'
import { routeListLog, routeListLogUpdate, mapApi, getRouteRouteListLogDetail, updateDeploymentImageReq } from '@/api/routelistlogs'
import { getDeviationData } from '@/api/deviation'
import { Watch } from 'vue-property-decorator'
import EmptyingTruck from './components/EmptyingTruck/EmptyingTruck'
import axios from 'axios'
import "leaflet.fullscreen/Control.FullScreen.css"
import "leaflet.fullscreen/Control.FullScreen"

@Component({
  components: {
    'emptying-truck': EmptyingTruck
  },
  methods: {
    ...mapMutations('app', ['toggleLoader'])
  },
  computed: {
     ...mapGetters('app', ['geoPosistionState']),
    getStaticData() {
      return this.staticData.containers || []
    },

    Styles() {
      return {
        height: `${this.mapHeight}px !important`
      };
    },

    descriptionHeight() {
      return {
        top: `${this.descriptionFinalheight}px !important`
      };
    },
     ListStyles() {
      return {
        height: `${this.listheight}px !important`
      };
    },

    

    getValid() {
      if (
        this.formData.containers.length !== ''  &&
        this.formData.deviation_id !== '' &&
        this.formData.comments !== ''
      ) {
        return true
      }
      return false
    }
  },
})
class RouteLists extends Vue {
  data() {
    return {
      imageDialog: false,
      satelite: false,
      show: false,
      deploymenetDetails: false,
      directions: [],
      selectedRouteIdx: 0,
      valid: false,
      selected: [2],
      brews: [],
      deviationList: [],
      staticData: {
        place: '',
        address: '',
        container: '',
        item: null,
        image_name: '',
        companyName: '',
      },
      formData: {
        id: '',
        deviation_id: '',
        deviation_comment: '',
        containers: [],
      },
      deploymentImage: '', 
      imageAvaliable: '',
      search: '',
      items: [],
      markers: [],
      mapRef: {},
      polyLine: [],
      polyLineArray: [],
      deploymentsList: [],
      rules: {
        requiredRules: v => !!v || this.$t('lang.required')
      },
      mapHeight: 800,
      descriptionFinalheight: 80,
      listheight: 400,
      divHeigth: '1',
      selectedIndex: null,
      emptyingTruck: false,
      routeLog: null,
      routeData: null,
      fromMarkerIcon: {},
      toMakerIcon: {},
      viaMarkerIcon: {},
      strings: [],
      stopsParameter: '',
      pointBarriersParameter: '',
      projection: '',
      fromPt: '',
      toPt: '',
      viaPt: '',
      deploymenetDisabled: false,
      updateImage: true,
      mapDivStyle: '',
      noOfRoutes: 0,
      emptyingTruckKey: 1,
      current_position: '',
      current_accuracy: '',
      firstTime: true,
      interval: '',
      timeByRoute: '',
      imgageHeight: '200px !important',
      btnload: false,
    }
  }
  mounted() {
    const self = this
    this.init()
  }

  created() {
  }

  init() {
    const self = this
    self.toggleLoader()
    self.initializeMap().then(() => {
      self.mapRef.on('locationfound', self.onLocationFound)
      self.mapRef.on('locationerror', self.onLocationError)
      self.interval  = setInterval(self.locate, 3000)
    })
    self.getRouteRouteLogs(1)
    self.getDeviationDataReq()
    self.mapCustomStyleForMap()
  }

  initializeMap() {
    return new Promise((resolve,reject) => {
      const self = this
      this.toggleLoader()
      var streets = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {id: 'MapID', tileSize: 512, zoomOffset: -1, attribution: "©OpenStreetMap"}),
      satelite   = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {id: 'MapID', tileSize: 512, zoomOffset: -1, attribution: "©OpenStreetMap"});
      self.mapRef= L.map('mapid', {
        fullscreenControl: true,
        fullscreenControl: {
          pseudoFullscreen: false
        },
        layers: [streets]
      }).setView([61.101125967765014, 7.445614605369609], 15);
      var baseMaps = {
        "streets": streets,
        "satellite  ": satelite
      }
      L.easyButton( '<a class="leaflet-control-zoom-out" href="#" role="button">I</a>', function(){
        self.show  = !self.show
      }, {position: 'topright'}).addTo(self.mapRef);
      var layerControl = L.control.layers(baseMaps, '', {position: 'bottomleft'});
      layerControl.addTo(self.mapRef);
      resolve();
    })
  }

  onLocationFound(e) {
    // if position defined, then remove the existing position marker and accuracy circle from the map
    const self = this
    if (self.current_position) {
      self.mapRef.removeLayer(self.current_position)
      self.mapRef.removeLayer(self.current_accuracy)
    }
    var radius = e.accuracy / 2;
    self.current_position = L.marker(e.latlng).addTo(self.mapRef)
      .bindPopup("You are within " + radius + " meters from this point").openPopup();
    self.current_accuracy = L.circle(e.latlng, radius).addTo(self.mapRef);
  }
  onLocationError(e) {
    this.handleNotificationSuccessOrFainMsg(e.message, 'error')
  }

  locate() {
    const self = this
    if (self.mapRef && (self.geoPosistionState || self.firstTime)) {
      self.firstTime = false
      self.mapRef.locate({setView: true});  
    }
  }

  pickFiles() {
    this.$refs.attachments.click()
  }

  getListOfFileNames(e) {
    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        this.createImage(files[i])
        this.$forceUpdate()
      }
    }
  }

  createImage(file) {
    var image = new Image()
    var reader = new FileReader()
    var self = this
    reader.onload = (e) => {
      self.deploymentImage = e.target.result
      self.updateImage = false
      self.$forceUpdate()
    }
    reader.readAsDataURL(file)
  }

  validateNumber() {
    const self = this
    if (self.formData.container > (self.staticData.container * 100)) {
      self.handleNotificationSuccessOrFainMsg(`${self.$t('lang.percentageValidationMsg')}`, 'error')
      return false;
    } 
    return true;
    
  }

  saveRoutes() {
    const self = this
    self.toggleLoader()
    self.btnload = true
    if (self.validateNumber()) {
      let attribute = {
        id: self.formData.id ? self.formData.id : -1,
        deployment_id: self.staticData.item.deployment_id,
        route_id: self.staticData.item.route_id,
        city: self.staticData.item.deployment_relation.city,
        location:
          self.staticData.item.deployment_relation.address +
          ' ' +
          self.staticData.item.deployment_relation.city,
        address: self.staticData.item.deployment_relation.address,
        lat: self.staticData.item.deployment_relation.lat,
        lon: self.staticData.item.deployment_relation.lon,
        deviation_category: self.formData.deviation_id,
        deviation_comment: self.formData.deviation_comment,
        employee_id: self.staticData.item.deployment_relation.address,
        status: self.staticData.item.deployment_relation.status,
        container: self.formData.container,
        deployment_relation: self.staticData.item.deployment_relation,
        route_log_id: self.routeLog.id,
        route_list_id: self.staticData.item.id,
        extra_empty_week: self.staticData.item.extra_empty_week
      }
      routeListLog(attribute)
        .then(response => {
          self.handleNotificationSuccessOrFainMsg(self.formData.id != -1 ? self.$t("lang.updated_successfully") : self.$t("lang.created_successfully"), 'success')
          self.toggleLoader();
          self.btnload = false
          if (response.data) {
            self.formData.id = response.data.id;
            self.formData.deviation_comment = response.data.deviation_comment
            self.formData.deviation_id = response.data.deviation_category
            self.formData.container = response.data.total_percentage
          }
          self.deploymenetDisabled = true; 
        })
        .catch((err) => self.handleServerSideValidationError(err))
    }
  }

  onClickOfChildRoute(index, lat, lang) {
    const self = this
    self.emptyingTruck = false
    self.mapDivStyle = ''
    self.updateImage = true
    var container = 0
    self.deploymenetDetails = true
    self.mapRef.flyTo([lat, lang], 17)
    // Data for the Right div top values
    self.staticData.item = self.items[index]
    self.staticData.address = self.items[index].deployment_relation.address
    self.staticData.container = self.items[index].deployment_relation.num_containers
    self.staticData.place = self.items[index].deployment_relation.city
    self.staticData.companyName = self.items[index].deployment_relation.vendor_relation.company_name
    self.toggleLoader();
    getRouteRouteListLogDetail({
      deploymentId: self.items[index].deployment_relation.id,
      routeListLogId: self.routeLog.id,
    }).then(response => {
        self.imageAvaliable = response.data.imageAvaliable
        self.deploymentImage = response.data.uploadImage 
        if (response.data.routeListLogs) {
          self.formData.id = response.data.routeListLogs.id;
          self.formData.deviation_comment = response.data.routeListLogs.deviation_comment
          self.formData.deviation_id = response.data.routeListLogs.deviation_category
          self.formData.container = response.data.routeListLogs.total_percentage
          self.deploymenetDisabled = true
        } else {
          self.formData = {}
          self.deploymenetDisabled = false 
        }
        self.toggleLoader();
    })
    this.mapCustomStyleForMap();
  }

  getRouteRouteLogs(type) {
    this.toggleLoader()
    getRouteRouteLog(this.$route.params.id)
      .then(response => {
        this.routeData = response.data
        this.routeLog = response.data.route_log
        if (!this.routeLog) {
          this.$router.push('/')
        } else {
          this.routeLog.noOfRoutes = this.noOfRoutes
          this.emptyingTruckKey++;
          if (type == 1) {
            this.getAllRouteLists()
          }
        }
      })
      .then(() => this.toggleLoader())
  }

  onCageLogsChange() {
    this.getRouteRouteLogs(2)
  }

  getAllRouteLists() {
    this.toggleLoader()
    let params = {
      routeRunId: this.routeLog.id
    }
    getAllRoutesList(params)
      .then(data => {
        this.items = data.data
        this.noOfRoutes = this.items.length
        this.routeLog.noOfRoutes = this.noOfRoutes
        this.calculateRoute()
      })
      .then(() => this.toggleLoader())
      .catch(error => {
        this.handleServerSideValidationError(error)
      })
  }

  getDeviationDataReq() {
    getDeviationData().then(response => {
      this.deviationList = response.data.data
    })
  }

  mapCustomStyleForMap() {
    const self = this
    setTimeout(() => {
      var screen_height = self.$refs.mainDiv.clientHeight
      self.listheight = screen_height - 22
      var details_div_height = self.$refs.detailsDiv.clientHeight
      self.descriptionFinalheight = details_div_height + 10; 
      var map_height = screen_height  - details_div_height
      map_height = map_height - 15
      self.mapHeight = map_height;
      self.imgageHeight = details_div_height - 80 +'px !important'
      return self.mapHeight
    }, 100);
    
   
  }

  emptyingTruckAction() {
    this.mapDivStyle = "display: none !important"
    this.emptyingTruck = true
  }

  calculateRoute() {
    const self = this
     let fromAddress = []
    if (self.$route.params.lat && self.$route.params.lon) {
       fromAddress = [self.$route.params.lat, self.$route.params.lon]
    } else {
     fromAddress = [self.routeData.start_point_relation.lat, self.routeData.start_point_relation.lon]
    }
    let toAddress = [self.routeData.end_point_relation.lat, self.routeData.end_point_relation.lon]
    self.drawRoute(fromAddress, toAddress, self.items)
  }

  drawRoute(fromAddress, toAddress, viaPoints) {
    const self = this;
    var noOfContainers = 0;
    var routeContainers = 0;
    self.fromMarkerIcon = new L.Icon({
      iconUrl: '/img/frommarker.png',
      iconSize: [35, 46],
      iconAnchor: [17, 46]
    });
    self.toMakerIcon = new L.Icon({
      iconUrl: '/img/tomarker.png',
      iconSize: [35, 46],
      iconAnchor: [17, 46]
    });
    self.viaMarkerIcon = new L.Icon({
      iconUrl: '/img/viamarker.png',
      iconSize: [35, 46],
      iconAnchor: [17, 46]
    });
    self.mapRef.flyTo(fromAddress, 12.5);
    self.markers.push(L.marker(fromAddress, {icon: self.fromMarkerIcon}).addTo(self.mapRef))
    self.markers.push(L.marker(toAddress, {icon: self.toMakerIcon}).addTo(self.mapRef))
    var projection = L.projection;
    self.projection = new L.Proj.CRS("EPSG:25833", "+proj=utm +zone=33 +ellps=GRS80 +units=m +no_defs").projection;
    self.fromPt = self.projection.project(new L.LatLng(fromAddress[0], fromAddress[1]));
    self.strings.push(self.fromPt.x + "," + self.fromPt.y);
    if (viaPoints.length > 0) {
      for (var i = 0; i < viaPoints.length; i++) {
        routeContainers = viaPoints[i].deployment_relation && viaPoints[i].deployment_relation.num_containers ? +viaPoints[i].deployment_relation.num_containers : 0;
        noOfContainers = noOfContainers + routeContainers;
        self.markers.push(L.marker([viaPoints[i].lat, viaPoints[i].lon], {icon: self.viaMarkerIcon}).addTo(self.mapRef));
        self.viaPt = self.projection.project(new L.LatLng(viaPoints[i].lat, viaPoints[i].lon));
        self.strings.push(self.viaPt.x + "," + self.viaPt.y);
      }
    }
    self.toPt = self.projection.project(new L.LatLng(toAddress[0], toAddress[1]));
    self.strings.push(self.toPt.x + "," + self.toPt.y);
    self.stopsParameter = self.strings.join(";");
    self.pointBarriersParameter = self.strings.join(";");
    self.strings = [];
    var params = {
      stops: self.stopsParameter,
      barriers: '',
      format: "json",
      lang: "nb-no",
      allowTravelInZeroEmissionZone: true,
      route_type: "alternative"
    };
    let vehicle_relation = self.routeData.route_log.vehicle_relation
    if(vehicle_relation.height){
      params.height = vehicle_relation.height / 100;
    }
    if (vehicle_relation.weight) {
      params.weight = vehicle_relation.weight / 1000;
    }
    if (vehicle_relation.length) {
      params.length = vehicle_relation.length / 100;
    }
    axios.get('https://www.vegvesen.no/ws/no/vegvesen/ruteplan/routingservice_v2_0/open/routingservice?', { params: params }).then(res => {
      var data = res.data;
      if (data.routes == null || data.routes.features == null) {
        self.handleNotificationSuccessOrFainMsg(`${self.$t('lang.routeError')}`, 'error')
        if (self.polyLine.length > 0) {
          for (var i = 0; i < self.polyLine.length; i++) {
            self.mapRef.removeLayer(self.polyLine[i]);
          }
        }
        return null;
      }
      self.resultData = res.data
      var features = [];
      data.routes.features.forEach(route => {
        var components = [];
        route.geometry.paths.forEach(path => {
          var points = [];
          path.forEach(point => {
            points.push(self.projection.unproject(new L.Point(point[0], point[1])));
          });
          components.push(points);
        });
        var geometry = new L.Polyline(components);
        features.push({
          geometry: geometry
        });
      });

      var totalBounds = null;
      var directions = data.directions;
      for (var i = 0; i < directions.length; i++) {
        directions[i].features.forEach((feature) => {
          if (feature.attributes.text.match(/\{([ERFKPS])(\d+)\}.*/i)) {
            feature.roadCat = feature.attributes.text.replace(/\{([ERFKPS])(\d+)\}.*/i, "$1");
            feature.roadNumber = parseInt(feature.attributes.text.replace(/\{([ERFKPS])(\d+)\}.*/i, "$2"));
            feature.attributes.text = feature.attributes.text.replace(/\{([ERFKPS])(\d+)\} (.*)/i, "$3");
          }
              
          feature.turnIconClass = this.getTurnIconForEsriManeuvre(feature.attributes.maneuverType);

        });
        directions[i].TotalTollLarge = data.routes.features.length != 0 ? data.routes.features[i].attributes['Total_Toll large'] : [];
        directions[i].TotalTollSmall = data.routes.features.length != 0 ? data.routes.features[i].attributes["Total_Toll small"] : [];
        directions[i].TotalTollLargeWithoutDiscount = data.routes.features[i].attributes["Total_Toll_Without_Discount large"];
        directions[i].TotalTollSmallWithoutDiscount = data.routes.features[i].attributes["Total_Toll_Without_Discount small"];
        //Unpack additional attributes
        if ((data.routes.features[i].attributes).attributes) {
          (data.routes.features[i].attributes).attributes.forEach((kvp) => {
            (directions[i].summary).statistics[kvp.key] = kvp.value;
          });
        }
        var bbox = directions[i].summary.envelope;
        directions[i].Bounds = new L.LatLngBounds(this.projection.unproject(new L.Point(bbox.xmin, bbox.ymin)), this.projection.unproject(new L.Point(bbox.xmax,bbox.ymax)));

        if (totalBounds == null) {
          totalBounds = directions[i].Bounds;
        } else {
          totalBounds.extend(directions[i].Bounds);
        }
        (features[i].geometry.options).routeId = directions[i].routeId;
      }

      self.directions = directions;
      var containerTime = noOfContainers * 15;
      var totalDriveTime = containerTime;
      if (self.directions[0]) {
        totalDriveTime = totalDriveTime + self.directions[0].summary.totalDriveTime;
      }
      self.timeByRoute =self.formatTimeFromMintes(totalDriveTime);
      self.$forceUpdate();

      let latLonArray = [];
      let polyLineArray = [];

      if (features.length > 0) {
        for (var i = 0; i < features.length; i++) {
          latLonArray = [];
          latLonArray = features[i].geometry._latlngs[0];
          polyLineArray = [];
          if (latLonArray.length > 0) {
            for (var k = 0; k < latLonArray.length; k++) {
              polyLineArray.push([latLonArray[k].lat, latLonArray[k].lng]);
            }
          }
          if (i == 0) {
            self.polyLine.push(L.polyline(polyLineArray, {color: 'red'}).addTo(this.mapRef));
          } else {
            self.polyLine.push(L.polyline(polyLineArray, {color: 'blue'}).addTo(this.mapRef));
          }
        }
      }
    }).catch((error) => {
      self.handleServerSideValidationError(error)
    })
  }

  getTurnIconForEsriManeuvre (esriManeuvreType) {
    switch (esriManeuvreType) {
      case "esriDMTStraight":
        return "icon-straight";
      case "esriDMTBearLeft":
        return "icon-bearleft";
      case "esriDMTBearRight":
        return "icon-bearright";
      case "esriDMTTurnLeft":
      case "esriDMTSharpLeft":
        return "icon-turnleft";
      case "esriDMTTurnRight":
      case "esriDMTSharpRight":
        return "icon-turnright";
      case "esriDMTUTurn":
        return "icon-uturn";
      case "esriDMTRoundabout":
        return "icon-roundabout";
      case "esriDMTDepart":
        return "icon-start";
      case "esriDMTStop":
        return "icon-stop";
      default:
        return "";
    }
  }

  updateDeploymentImage() {
    const self = this
    var imagefile = document.querySelector('#deployment_image');
    const formData = new FormData()
    formData.append('deployment_image', imagefile.files[0]);
    formData.append('id', self.staticData.item.deployment_id);
    updateDeploymentImageReq(formData).
      then(response => {
        self.updateImage = true
        self.imageAvaliable = 1
        self.imageDialog = false
        self.handleNotificationSuccessOrFainMsg(`${self.$t('lang.imageUploadSuccess')}`, 'success')
    })
    .catch((err) => self.handleServerSideValidationError(err))

  }

  beforeDestroy () {
    clearInterval(this.interval);
  }
}

export default RouteLists
</script>

<style scoped>
#mapid {
  position: sticky !important;
}
#listDiv {
  border-right: 1px solid #e0e0e0 !important;
}

.mainDiv {
  height: 90vh;
  padding: 6px !important;
}

.mainRow {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.button_actions {
  height: 86px !important;
  margin-top: -11px !important;
}


@media all and (max-width: 700px) {
  .button_actions {
    min-width: 53px !important;
    margin-left: -13px !important;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .col-lg-2 {
    max-width: 9.666667% !important;
  }
}
.v-input__control {
  height: 20px !important;
}

.v-application .deep-orange--text {
    color: #e25303 !important;
    caret-color: #e25303 !important;
}
.container {
  padding: 0px !important
}
#deploymenetDetailsDiv {
  margin-bottom: -20px !important
}
.v-input {
  margin-top: -15px !important;
}

.cusdum_divider {
  border: 1px solid gray !important;
}

.searchBox {
  position: absolute !important;
  z-index: 100 !important;
  top: 80px !important;
  right: 30px !important;
}

.searchBox1 {
  position: absolute !important;
  z-index: 100 !important;
  top: 60px !important;
  right: 113px !important;
}

.selectedRoute {
  position: absolute;
  margin-top: 50px;
  height: 70px;
  z-index: 100 !important;
  top: 5px !important;
  right: 2px !important;
}

.displayRouteDetails {
  max-height: 300px !important;
  overflow: auto !important;
}
</style>
