import request from '@/utils/request';

const BASE_URL = process.env.VUE_APP_BASE_URL;

/**
 * 
 * Post Route List Logs
 */
const routeListLog = (data) => {
    return request({
        url: `${BASE_URL}/${data.route_id}/routeListLogs`,
        method: 'POST',
        data
    });
}

/**
 * 
 * Put Route List Logs
 */
const routeListLogUpdate = (id, data) => {
    return request({
        url: `${BASE_URL}/${data.route_id}/routeListLogs/${id}`,
        method: 'PUT',
        data
    });
}

const getRouteRouteListLogDetail = (data) => {
    return request({
        url: `${BASE_URL}/getRouteRouteListLogDetail/${data.deploymentId}/${data.routeListLogId}`,
        method: 'get',
        data
    });
}

const updateDeploymentImageReq = (data) => {
    return request({
        url: `${BASE_URL}/deployment/updateImage`,
        method: 'POST',
        data
    });
}

export {
    routeListLog,
    routeListLogUpdate,
    getRouteRouteListLogDetail,
    updateDeploymentImageReq
}