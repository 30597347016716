import request from '@/utils/request'

const BASE_URL = process.env.VUE_APP_BASE_URL

/**
 * Get Route Route Log
 */
const saveCageLog = data => {
  return request({
    url: `${BASE_URL}/cagelogs`,
    method: 'POST',
    data
  })
}

/**
 * Delete Log
 */
const deleteLog = id => {
  return request({
    url: `${BASE_URL}/cagelogs/${id}`,
    method: 'DELETE'
  })
}

export { saveCageLog, deleteLog }
